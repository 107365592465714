// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'mdDriverImprovementProgramAAA', // this used as the field name as well as the unique identifier
    productIds: [
      '31e1c166-0425-4209-2a50-08dcd6510d46', // AAA Maryland Driver Improvement Program
      'be09fed2-e1d8-481d-3f84-08dc4824bf0e', // for QA using the Design QA Product, only in ML
    ],
    errorMsg: `You must confirm that the student is at least 18 years of age to purchase <strong>AAA Maryland Driver Improvement Program</strong> by typing "<strong>I agree</strong>" in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'iAgree', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      To purchase <strong>AAA Maryland Driver Improvement Program</strong>, the student confirms that they are at least 18
      years of age at the time of purchase. Please type &quot;<strong>I agree</strong>&quot; below to confirm.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
