import axios from 'axios';
import cookies from '../utils/cookies';

// https://ascservices.amersc.com/Products/api/v1/Public/GetProductsBy/?host=www.ustoperatortraining.com&couponcode=null&unpublished=false
// https://ascservices.amersc.com/Carts/api/v1/CartProducts/?completeProducts=false&unpublished=false
// https://ascservices.amersc.com/Carts/api/v1/CartProducts/b120b052-f418-410a-a4ec-1fde8adc3e32/1?completeProducts=false
// https://themes.amersc.com/shop/templates/ustoperatortraining.com/css/shop.css"

let THEME_BASE_URL = 'https://dlthemes.amersc.com/shop/templates';
let API_BASE_URL = 'https://dlapi.certus.com';
let UNPUBLISHED = cookies.get('unpublished') === '1' ? true : false;

const VITE_APP_MODE = import.meta.env.MODE;
const NODE_ENV = process.env.NODE_ENV.toLowerCase();
const isDevMode = import.meta.env.DEV;
// const isProdMode = import.meta.env.PROD;

// console.log('Mode:', VITE_APP_MODE);
// console.log('NODE_ENV:', NODE_ENV);
// console.log('isDev:', isDevMode);
// console.log('isProd:', isProdMode);

if (VITE_APP_MODE === 'localDev' && isDevMode) {
  API_BASE_URL = API_BASE_URL.replace('dlapi', 'api');
}
if (VITE_APP_MODE === 'staging' && isDevMode) {
  API_BASE_URL = API_BASE_URL.replace('dlapi', 'mlapi');
  // THEME_BASE_URL = THEME_BASE_URL.replace('dlthemes', 'mlthemes');
}
if (NODE_ENV === 'staging') {
  API_BASE_URL = API_BASE_URL.replace('dlapi', 'mlapi');
  THEME_BASE_URL = THEME_BASE_URL.replace('dlthemes', 'mlthemes');
}
if (NODE_ENV === 'production') {
  API_BASE_URL = API_BASE_URL.replace('dlapi', 'api');
  THEME_BASE_URL = THEME_BASE_URL.replace('dlthemes', 'themes');
}

const api = {
  getTemplate: (domain) => {
    return axios.get(`${THEME_BASE_URL}/${domain}/index.html`);
  },

  getCart: (cartId) => {
    // adding timestamp to prevent any getCart API call from using the http cache from the browser
    // the timestamp query string is ignored by the API endpoint, it doesn't use it in any way
    const timestamp = new Date().getTime();
    const cacheBust = `&timestamp=${timestamp}`;
    return axios.get(
      `${API_BASE_URL}/Carts/api/v1/Carts/${cartId.toLowerCase()}/?completeProducts=false&unpublished=${UNPUBLISHED}${cacheBust}`
    );
  },

  createCart: (cartData) => {
    const { productIds, pricingVariant, groupId, coupon, sourceHost } = cartData;
    const data = {
      cartId: null, // set to null because we are creating a new cart
      productIds, // array, lowercasing is applied before it gets here
      pricingVariant: pricingVariant && pricingVariant.toLowerCase(),
      groupId: groupId && groupId.toLowerCase(),
      coupon: coupon && coupon.toLowerCase(),
      sourceHost: sourceHost && sourceHost.toLowerCase(),
    };
    return axios.post(
      `${API_BASE_URL}/Carts/api/v1/Carts/CartProductPricingChange/?completeProducts=false&unpublished=${UNPUBLISHED}`,
      data
    );
  },

  updateCart: (cartData) => {
    const { cartId, productIds, pricingVariant, groupId, coupon, sourceHost } = cartData;
    const data = {
      cartId: cartId && cartId.toLowerCase(),
      productIds, // array, lowercasing is applied before it gets here
      pricingVariant: pricingVariant && pricingVariant.toLowerCase(),
      groupId: groupId && groupId.toLowerCase(),
      coupon: coupon && coupon.toLowerCase(),
      sourceHost: sourceHost && sourceHost.toLowerCase(),
    };
    return axios.post(
      `${API_BASE_URL}/Carts/api/v1/Carts/CartProductPricingChange/?completeProducts=false&unpublished=${UNPUBLISHED}`,
      data
    );
  },

  // not used but retaining for records
  getProductsByIds: (productIds) => {
    const productIdsQuery = productIds.join('&productIds=');
    return axios.get(
      `${API_BASE_URL}/Products/api/v1/Public/GetProductsByIds/?productIds=${productIdsQuery}&unpublished=${UNPUBLISHED}`
    );
  },

  updateProduct: (productData) => {
    const { cartId, productId, cartProductId, quantity, isSelected } = productData;
    const data = {
      cart_ProductId: cartProductId, // upsell cart_ProductId
      cartId: cartId, // cartId
      productId: productId, // main productId
      quantity: quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
      isSelected: isSelected, // true/false, add or remove upsell
    };
    return axios.put(`${API_BASE_URL}/Carts/api/v1/CartProducts/?completeProducts=false&unpublished=${UNPUBLISHED}`, data);
  },

  updateUpsell: (upsellData) => {
    const { cartId, productId, cartProductId, quantity, isSelected } = upsellData;
    const data = {
      cart_ProductId: cartProductId, // upsell cart_ProductId
      cartId: cartId, // cartId
      productId: productId,
      quantity: quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
      isSelected: isSelected, // true/false, add or remove upsell
    };
    return axios.put(
      `${API_BASE_URL}/Carts/api/v1/CartProducts/UpdateUpsell/?completeProducts=false&unpublished=${UNPUBLISHED}`,
      data
    );
  },

  removeProduct: (cartId, cartProductId) => {
    return axios.delete(
      `${API_BASE_URL}/Carts/api/v1/CartProducts/${cartId}/${cartProductId}/?completeProducts=false&unpublished=${UNPUBLISHED}`
    );
  },

  updateCartBillingInfo: (billingInfo) => {
    const { cartId, firstName, lastName, email } = billingInfo;
    const data = {
      cartId,
      billingFirstName: firstName,
      billingLastName: lastName,
      billingEmail: email,
    };
    return axios.put(`${API_BASE_URL}/Carts/api/v1/Carts/UpdateCartBillingInfo`, data);
  },
};

export default api;
