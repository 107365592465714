// generate flat array of products from cart items and upsells
const getCartProducts = (cart) => {
  const products = cart.cartProducts
    .map((item) => {
      const cartItem = {
        item_id: item.productId,
        item_name: item.name,
        item_variant: item.pricingUsed,
        price: item.unitPriceWithDiscountAndFeeIfHidden,
        quantity: item.quantity,
        discount: item.unitDiscountAmount,
        thirdPartyFeeTotal: item.thirdPartyFeeTotal,
        coupon: item.discountCode,
        sourceProductId: item.sourceProductId,
        upsellType: item.upsellType,
      };

      const selectedUpsells = item?.upSellProducts
        .filter((upsell) => upsell.isSelected)
        .map((upsellItem) => {
          return {
            item_id: upsellItem.productId,
            item_name: upsellItem.name,
            item_variant: upsellItem.pricingUsed,
            price: upsellItem.unitPriceWithDiscountAndFeeIfHidden,
            quantity: upsellItem.quantity,
            discount: upsellItem.unitDiscountAmount,
            thirdPartyFeeTotal: upsellItem.thirdPartyFeeTotal,
            coupon: upsellItem.discountCode,
            sourceProductId: upsellItem.sourceProductId,
            upsellType: upsellItem.upsellType,
          };
        });

      return [cartItem, ...selectedUpsells];
    })
    .flat(); // needs to be flattened because the map function returns an array of arrays

  return products;
};

export const ecomViewCart = (cart) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      checkout: {
        products: [...getCartProducts(cart)],
      },
    },
  });
};

export const ecomAbandonCart = (cart, userInfo) => {
  window.dataLayer.push({
    event: 'abandon_cart',
    ecommerce: {
      abandon_cart: {
        user_info: {
          billingFirstName: userInfo.firstName,
          billingLastName: userInfo.lastName,
          billingEmail: userInfo.email,
        },
        products: [...getCartProducts(cart)],
      },
    },
  });
};

export const ecomAddProduct = (item) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      add: {
        products: [
          {
            item_id: item.productId,
            item_name: item.name,
            item_variant: item.pricingUsed,
            price: item.unitPriceWithDiscountAndFeeIfHidden,
            quantity: item.quantity,
            discount: item.unitDiscountAmount,
            thirdPartyFeeTotal: item.thirdPartyFeeTotal,
            coupon: item.discountCode,
            sourceProductId: item.sourceProductId,
            upsellType: item.upsellType,
          },
        ],
      },
    },
  });
};

export const ecomRemoveProduct = (item) => {
  const selectedUpsells = item?.upSellProducts
    .filter((upsell) => upsell.isSelected)
    .map((item) => {
      return {
        item_id: item.productId,
        item_name: item.name,
        item_variant: item.pricingUsed,
        price: item.unitPriceWithDiscountAndFeeIfHidden,
        quantity: item.quantity,
        discount: item.unitDiscountAmount,
        thirdPartyFeeTotal: item.thirdPartyFeeTotal,
        coupon: item.discountCode,
        sourceProductId: item.sourceProductId,
        upsellType: item.upsellType,
      };
    });
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            item_id: item.productId,
            item_name: item.name,
            item_variant: item.pricingUsed,
            price: item.unitPriceWithDiscountAndFeeIfHidden,
            quantity: item.quantity,
            discount: item.unitDiscountAmount,
            thirdPartyFeeTotal: item.thirdPartyFeeTotal,
            coupon: item.discountCode,
            sourceProductId: item.sourceProductId,
            upsellType: item.upsellType,
          },
          ...selectedUpsells,
        ],
      },
    },
  });
};
