import { useEffect, useContext, useCallback, useMemo } from 'react';
import { CartContext } from '../../store/CartContext';
import CheckboxItem from './CheckboxItem';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
CheckboxGroup.propTypes = {
  cartProductIds: PropTypes.array.isRequired,
  fieldType: PropTypes.string.isRequired,
  attestItems: PropTypes.array.isRequired,
  attestForm: PropTypes.object.isRequired,
  setAttestForm: PropTypes.func.isRequired,
};

export default function CheckboxGroup({ cartProductIds, fieldType, attestItems, attestForm, setAttestForm }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const showItem = useCallback(
    (field) => {
      // check if one of the field productIds is in the cart
      const shouldValidate = field.productIds.some((productId) => cartProductIds.includes(productId));
      const isBulkOrder = field.productIds.some(
        (productId) => cartState.cartProducts.find((item) => item.productId === productId)?.quantity > 1
      );
      return field.onlyForBulk ? shouldValidate && isBulkOrder : shouldValidate;
    },
    [cartProductIds, cartState.cartProducts]
  );

  const orderedAttestItems = useMemo(
    () =>
      attestItems.sort(
        (itemA, itemB) =>
          cartProductIds.findIndex((id) => itemA.productIds.includes(id)) -
          cartProductIds.findIndex((id) => itemB.productIds.includes(id))
      ),
    [attestItems, cartProductIds]
  );

  const filteredAttestItems = useMemo(
    () =>
      cartProductIds
        .map((id) => orderedAttestItems.find((item) => item.productIds.includes(id)))
        .filter(Boolean)
        .map((field) => {
          const displayItem = showItem(field);

          if (displayItem) {
            return field;
          }
        })
        .filter(Boolean),
    [cartProductIds, orderedAttestItems, showItem]
  );

  const checkboxNames = useMemo(() => filteredAttestItems.map((item) => item.name), [filteredAttestItems]);
  const isGroupValid = Object.keys(cartState.validation.errors).every((item) => !checkboxNames.includes(item));

  const displayGroup = filteredAttestItems.length > 0;

  // this adds all the checkboxes info into the parent AttestationForm component's state
  useEffect(() => {
    const sessionAttestForm = sessionStorage.getItem('attestForm') && JSON.parse(sessionStorage.getItem('attestForm'));

    setAttestForm((state) => {
      // collecting all the checkboxes so that we can them as form fields
      const checkboxItems = filteredAttestItems.reduce((acc, cur) => {
        if (showItem(cur)) {
          acc[cur.name] = {
            value: sessionAttestForm?.fields[cur.name]
              ? sessionAttestForm?.fields[cur.name].value
              : state.fields[cur.name]
              ? state.fields[cur.name].value
              : false,
            isValid: sessionAttestForm?.fields[cur.name]
              ? sessionAttestForm?.fields[cur.name].isValid
              : state.fields[cur.name]
              ? state.fields[cur.name].isValid
              : false,
          };
        }
        return acc;
      }, {});

      return {
        ...state,
        fields: {
          ...state.fields,
          ...checkboxItems,
        },
      };
    });
  }, [filteredAttestItems, setAttestForm, showItem]);

  // error collection handler for the grouped field
  useEffect(() => {
    const isGroupValid = attestForm.fields[fieldType]?.isValid || false;
    const errorCollection = {};
    let removeErrorKeys = [];

    for (const item of orderedAttestItems) {
      const displayItem = showItem(item);

      if (!displayItem || isGroupValid) {
        // removing the current field's error if attestation is not displayed
        // or if the grouped field have is valid
        delete errorCollection[item.name];
        removeErrorKeys.push(item.name);
      } else {
        errorCollection[item.name] = item.errorMsg; // current field's errorMsg
      }
    }

    const validCheckboxes = orderedAttestItems.filter((item) => attestForm.fields[item.name]?.isValid);
    removeErrorKeys = [...removeErrorKeys, ...validCheckboxes.map((item) => item.name)];

    cartDispatch({
      type: 'VALIDATION',
      payload: {
        addErrors: errorCollection,
        removeErrors: removeErrorKeys.length ? removeErrorKeys : null,
      },
    });
  }, [attestForm.fields, cartDispatch, fieldType, orderedAttestItems, showItem]);

  // this is ONLY for the checkboxes
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;

    setAttestForm((state) => {
      // setting value manually here so that I can check latest state before updating
      state.fields[name].isValid = theValue;

      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            value: theValue,
            isValid: theValue,
          },
        },
        touched: {
          ...state.touched,
          [name]: true,
        },
      };
    });
  };

  const handleModalClose = (fieldName) => {
    setAttestForm((state) => ({
      ...state,
      fields: {
        ...state.fields,
        [fieldName]: {
          value: true,
          isValid: true,
        },
      },
    }));
  };

  const attestationList = filteredAttestItems?.map((item) => (
    <CheckboxItem
      key={item.name}
      cartState={cartState}
      attestForm={attestForm}
      attestItem={item}
      handleModalClose={handleModalClose}
      handleChange={handleChange}
      fieldType={fieldType}
    />
  ));

  if (displayGroup) {
    return (
      <>
        <div
          className={`card attestation-group notranslate ${
            cartState.validation.formTouched && !isGroupValid
              ? 'bg-warning bg-opacity-10 border-danger-subtle'
              : 'bg-warning bg-opacity-10 border-warning-subtle'
          }  my-2`}
        >
          <div className="card-body">{attestationList}</div>
        </div>
      </>
    );
  }

  return null;
}
