import { useState, useEffect, useContext, useRef } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { formatCurrency as currency } from '../../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../../utils/amountHelper';
import { ecomRemoveProduct } from '../../utils/ecomHelper';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';
import UpsellDisplay from './UpsellDisplay';
import UpsellGroups from './UpsellGroups';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
CartItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function CartItem({ item }) {
  const cartId = item.cartId;
  // console.log('cartItem', item);

  const [cartState, cartDispatch] = useContext(CartContext);

  const isSingle = cartState.cartProducts.length === 1;

  const displayUpsells = item.upSellProducts.length
    ? item.upSellProducts.filter((upsell) => upsell.isSelected || upsell.upsellSectionId !== 5).length > 0
    : false;

  const hasAccessoryUpsells = item.upSellProducts.length
    ? item.upSellProducts.filter((upsell) => upsell.upsellSectionId !== 5).length > 0
    : false;

  const [itemState, setItemState] = useState({
    isLoading: false,
    isUpdatingItem: false,
    isRemovingItem: false,
    ...item,
  });

  useEffect(() => {
    setItemState((state) => ({
      ...state,
      ...item,
    }));
  }, [item]);

  // dont use upsell modal if there is no upsell
  useEffect(() => {
    if (item.upSellProducts.length === 0 || isSingle) {
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          viewedUpsells: true,
        },
      });
    }
  }, [cartDispatch, isSingle, item.upSellProducts.length]);

  const qtyDebounce = useRef();

  const itemDispayPrice = getDisplayPrice(item);

  const handleQtyChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    let quantity = itemState.quantity;

    if (name === 'quantity' && Number(theValue) > 0) {
      quantity = Number(theValue);
    }
    if (name === 'decrement' && itemState.quantity > 1) {
      quantity = quantity - 1;
    }
    if (name === 'increment') {
      quantity = quantity + 1;
    }

    if (itemState.quantity !== quantity) {
      setItemState((state) => ({
        ...state,
        quantity,
        isLoading: true,
      }));

      cartDispatch({
        type: 'UPDATE',
        payload: {
          isLoading: true,
        },
      });

      // trigger to hide the toast notification
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          showNotification: false,
        },
      });

      // simple debouncing to prevent unecessary API calls when a user continues to change quantity
      clearTimeout(qtyDebounce.current);
      qtyDebounce.current = setTimeout(() => {
        updateQty(quantity);
      }, 300);
    }
  };

  const updateQty = (qty) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        isUpdatingCart: true,
      },
    });

    setItemState((state) => ({
      ...state,
      isUpdatingItem: true,
    }));

    const product = {
      cartId: cartId,
      productId: item.productId,
      cartProductId: item.cart_ProductId,
      quantity: qty,
      isSelected: item.isSelected,
    };
    API.updateProduct(product)
      .then(async (res) => {
        // update cart state globally
        let cart = res.data;

        setItemState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingItem: false,
        }));

        // updating local cart with data from API
        cartDispatch({
          type: 'UPDATE',
          payload: {
            ...cart,
            isLoading: false,
            isUpdatingCart: false,
          },
        });

        // trigger to show the toast notification
        cartDispatch({
          type: 'EXPERIENCE',
          payload: {
            showNotification: true,
          },
        });
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        // reload the page if the API call fails, ideally show an error message
        window.location.reload();
      });
  };

  const handleRemoveFromCart = () => {
    setItemState((state) => ({
      ...state,
      isRemovingItem: true,
    }));

    // console.log('cartProductId', cartProductId);
    cartDispatch({
      type: 'UPDATE',
      payload: {
        isLoading: true,
        isUpdatingCart: true,
      },
    });

    // trigger to hide the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showNotification: false,
      },
    });

    // actual API call that updates the cart
    API.removeProduct(cartId, item.cart_ProductId)
      .then(async (res) => {
        // update cart state globally
        let cart = res.data;

        setItemState((state) => ({
          ...state,
          isUpdatingItem: false,
          isRemovingItem: false,
        }));

        // updating local cart with data from API
        cartDispatch({
          type: 'UPDATE',
          payload: {
            ...cart,
            isLoading: false,
            isUpdatingCart: false,
          },
        });

        // trigger to show the toast notification
        cartDispatch({
          type: 'EXPERIENCE',
          payload: {
            showNotification: true,
          },
        });

        ecomRemoveProduct(item);
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        // reload the page if the API call fails, ideally show an error message
        window.location.reload();
      });
  };

  const showBulkRates = () => {
    // trigger to show the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showBulkRates: {
          name: item.name,
          bulkRates: item.cartProductBulkTiers,
        },
      },
    });
  };

  // for single-product cart UI/UX and has accessory upsells
  if (isSingle && hasAccessoryUpsells) {
    return (
      <>
        <div className="single-cart-item">{item.upSellProducts.length > 0 && <UpsellGroups item={item} />}</div>
      </>
    );
  }

  // for multi-product cart UI/UX or single-product cart withouth accessory upsells
  return (
    <div
      className={`card product mb-2 ${itemState.isRemovingItem ? 'is-removing-item' : ''}`}
      data-product-id={item.productId}
    >
      <div className="card-body d-flex flex-column py-3">
        <div className="item-details d-sm-flex justify-content-start justify-content-sm-between w-100 mb-3">
          <h2 className="item-name h4 fw-600 my-0 me-2">
            {item.name}{' '}
            <span className="item-price fw-600 small d-inline-block d-sm-none px-0 me-0">
              (
              {item.discountSource && item.unitDiscountAmount > 0 && (
                <span className="orig-price d-inline-block me-2">{currency(getOrigPrice(item))}</span>
              )}
              <span className="current-price d-inline-block">{currency(itemDispayPrice)}</span>)
            </span>
          </h2>
          {/* <span className="item-price d-inline-block fw-600 text-end px-0 me-0">
            <span className="d-sm-none">(</span>
            {item.discountSource && item.unitDiscountAmount > 0 && (
              <span className="orig-price me-2">{currency(getOrigPrice(item))}</span>
            )}
            {currency(itemDispayPrice)}
            <span className="d-sm-none">)</span>
          </span> */}
        </div>
        <div className="item-action d-flex justify-content-start align-items-top align-items-sm-center mb-sm-1">
          <div className="item-price-wrap d-none d-sm-block pe-3">
            <span className="item-price-label small me-2">Unit Price:</span>
            <span className="item-price fw-600 d-block d-sm-inline-block px-0 me-0">
              {item.discountSource && item.unitDiscountAmount > 0 && (
                <span className="orig-price d-inline-block me-2">{currency(getOrigPrice(item))}</span>
              )}
              <span className="current-price d-inline-block">{currency(itemDispayPrice)}</span>
            </span>
          </div>
          <div className="item-quantity d-flex flex-row align-items-center notranslate">
            {/* <button
              className="delete-btn btn text-muted d-sm-none"
              onClick={handleRemoveFromCart}
              type="button"
              name="delete"
              aria-label="Delete Item"
              disabled={cartState.isLoading || itemState.isUpdatingItem}
            >
              {itemState.isUpdatingItem ? (
                <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
              ) : (
                <DeleteIcon aria-hidden="true" />
              )}
            </button> */}

            {item.upsellType === 'Retake' ? (
              <div className="me-4">Qty: {itemState.quantity}</div>
            ) : (
              <div
                className={`input-group me-3 notranslate ${
                  cartState.isUpdatingCart && !itemState.isUpdatingItem
                    ? 'is-loading'
                    : itemState.isUpdatingItem
                    ? 'is-updating-item'
                    : ''
                }`}
              >
                <button
                  className="qty-btn btn btn-sm btn-light"
                  onClick={handleQtyChange}
                  type="button"
                  name="decrement"
                  disabled={itemState.quantity === 1}
                  aria-label="Decrement"
                >
                  &#8722;
                </button>
                <label className="visually-hidden" htmlFor={`item-qty-${item.cart_ProductId}`}>
                  Quantity
                </label>
                <input
                  id={`item-qty-${item.cart_ProductId}`}
                  className="qty-input form-control"
                  name="quantity"
                  type="number"
                  value={itemState.quantity}
                  onChange={handleQtyChange}
                  min="1"
                  aria-label="Item Quantity"
                />
                <button
                  className="qty-btn btn btn-sm btn-light"
                  onClick={handleQtyChange}
                  type="button"
                  name="increment"
                  aria-label="Increment"
                >
                  &#43;
                </button>
              </div>
            )}

            {item.cartProductBulkTiers && item.cartProductBulkTiers.length > 0 && (
              <div className="qty-discount text-muted lh-1">
                <button
                  className="btn-toggle fw-400 small text-reset lh-1 p-0"
                  type="button"
                  // data-bs-toggle="modal"
                  // data-bs-target={`#upsells-bulk-rate-${item.cart_ProductId}`}
                  onClick={showBulkRates}
                >
                  Bulk Rates<span className="info-icon align-middle">?</span>
                </button>
              </div>
            )}
          </div>
          <div className="item-remove text-end ms-auto mb-1 mb-lg-0">
            <button
              className="delete-btn btn text-muted"
              onClick={handleRemoveFromCart}
              type="button"
              name="delete"
              aria-label="Delete Item"
              disabled={cartState.isLoading || itemState.isRemovingItem}
            >
              {itemState.isRemovingItem ? (
                <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
              ) : (
                <DeleteIcon aria-hidden="true" />
              )}
            </button>
          </div>
        </div>

        {/* Render Upsell Groups */}
        {!isSingle && displayUpsells && (
          <div className="item-upsells mt-3 mt-sm-2">
            <UpsellDisplay isSingle={isSingle} item={item} />
          </div>
        )}
      </div>
    </div>
  );
}
