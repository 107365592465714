import { useContext } from 'react';
import { CartContext } from '../../store/CartContext';
import useBrand from '../../hooks/useBrand';
// import CouponField from './CouponField';

export default function CheckoutBtn() {
  const [cartState, cartDispatch] = useContext(CartContext);
  const isCartLoading = cartState.isLoading;
  const isUpdatingBillingInfo = cartState.isUpdatingBillingInfo;
  const isCartValid = Object.keys(cartState.validation.errors).length === 0;
  const { domain } = useBrand();

  const VITE_APP_MODE = import.meta.env.MODE;
  const NODE_ENV = process.env.NODE_ENV.toLowerCase();
  const isDevMode = import.meta.env.DEV;

  let shopUrl = `https://dlshop.${domain}`;

  if (VITE_APP_MODE === 'localDev' && isDevMode) {
    shopUrl = `https://shop.${domain}`;
  }
  if (NODE_ENV === 'staging') {
    shopUrl = `https://mlshop.${domain}`;
  }
  if (NODE_ENV === 'production') {
    shopUrl = `https://shop.${domain}`;
  }

  const viewedUpsells = cartState.userExperience.viewedUpsells;

  const checkUrl = `${shopUrl}/checkout/?cartId=${cartState.cartId}`;

  const handleCheckout = (event) => {
    event.preventDefault();

    if (isCartValid) {
      if (viewedUpsells) {
        sessionStorage.setItem('viewCheckout', true);
        window.location.href = checkUrl;
      } else {
        cartDispatch({
          type: 'EXPERIENCE',
          payload: {
            showUpsellModal: true,
          },
        });
      }
    } else {
      cartDispatch({
        type: 'VALIDATION',
        payload: {
          showErrors: true,
          formTouched: true,
        },
      });
    }
  };

  return (
    <button
      type="button"
      className={`checkout-btn btn btn-lg btn-success w-100 py-3 py-md-2 ${
        isCartLoading || isUpdatingBillingInfo ? 'disabled' : ''
      }`}
      onClick={handleCheckout}
      name="Checkout Now"
    >
      Checkout Now
    </button>
  );
}
