import { useState, useEffect, useContext } from 'react';
import { CartContext } from '../../store/CartContext';
import API from '../../services/apiService';
import { ecomAbandonCart } from '../../utils/ecomHelper';

export default function UserInfoForm() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const [isChanged, setIsChanged] = useState(false);

  const [userInfo, setUserInfo] = useState({
    firstName: {
      value: cartState.billingFirstName || '',
      isValid: cartState.billingFirstName !== null && cartState.billingFirstName.trim() !== '',
      isTouched: cartState.validation.formTouched || false,
    },
    lastName: {
      value: cartState.billingLastName || '',
      isValid: cartState.billingLastName !== null && cartState.billingLastName.trim() !== '',
      isTouched: cartState.validation.formTouched || false,
    },
    email: {
      value: cartState.billingEmail || '',
      isValid: cartState.billingEmail !== null && cartState.billingEmail.trim() !== '',
      isTouched: cartState.validation.formTouched || false,
    },
  });

  useEffect(() => {
    setUserInfo((state) => ({
      ...state,
      firstName: {
        ...state.firstName,
        isTouched: cartState.validation.formTouched || false,
      },
      lastName: {
        ...state.lastName,
        isTouched: cartState.validation.formTouched || false,
      },
      email: {
        ...state.email,
        isTouched: cartState.validation.formTouched || false,
      },
    }));
  }, [cartState.validation.formTouched]);

  useEffect(() => {
    // add error to error collection if any fields are invalid
    if (Object.values(userInfo).some((field) => !field.isValid)) {
      cartDispatch({
        type: 'VALIDATION',
        payload: {
          addErrors: {
            userInfoForm:
              'Please enter your <strong>first name, last name, and a valid email address</strong> in the highlighted form.',
          },
        },
      });
    }
    // remove error from error collection if all fields are valid
    else {
      cartDispatch({
        type: 'VALIDATION',
        payload: {
          removeErrors: ['userInfoForm'],
        },
      });
    }
  }, [cartDispatch, userInfo]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;

    setUserInfo((state) => ({
      ...state,
      [name]: {
        ...state[name],
        value: theValue,
        isValid: theValue.trim().length > 0 && theValue.trim().length <= 50,
        isTouched: true,
      },
    }));

    setIsChanged(true);
  };

  const handleBlur = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // this the updated userInfo object that will be set to state
    const updatedUserInfo = {
      ...userInfo,
      [name]: {
        ...userInfo[name],
        isValid: name === 'email' ? emailRegex.test(theValue) : theValue.trim().length > 0 && theValue.trim().length <= 50,
        isTouched: true,
      },
    };

    setUserInfo((state) => ({
      ...state,
      ...updatedUserInfo,
    }));

    if (Object.values(updatedUserInfo).every((field) => field.isValid) && isChanged) {
      // console.log('Updating cart billing info data', userInfo);
      cartDispatch({
        type: 'UPDATE',
        payload: {
          isUpdatingBillingInfo: true,
        },
      });

      const billingInfo = {
        cartId: cartState.cartId,
        firstName: userInfo.firstName.value,
        lastName: userInfo.lastName.value,
        email: userInfo.email.value,
      };
      API.updateCartBillingInfo(billingInfo)
        .then((res) => {
          const cart = res.data;

          cartDispatch({
            type: 'UPDATE',
            payload: {
              isUpdatingBillingInfo: false,
              billingFirstName: cart.billingFirstName,
              billingLastName: cart.billingLastName,
              billingEmail: cart.billingEmail,
            },
          });
        })
        .catch((err) => {
          console.error(err?.response?.data);
          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
          }
        });

      // send abandon cart event to GTM
      ecomAbandonCart(cartState, billingInfo);
    }

    setIsChanged(false);
  };

  return (
    <div
      className={`info-form border rounded bg-light-subtle mb-4 p-3 ${
        cartState.validation.formTouched &&
        Object.keys(cartState.validation.errors).includes('userInfoForm') &&
        'border-danger-subtle'
      }`}
    >
      <h2 className="fs-5 fw-500 mb-3">Customer Information</h2>
      <form>
        <div className="mb-3">
          <label className="form-label mb-0 ps-1" htmlFor="first-name">
            First Name
          </label>
          <input
            className={`form-control fw-600 ${userInfo.firstName.isTouched && !userInfo.firstName.isValid && 'is-invalid'}`}
            id="first-name"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={userInfo.firstName.value}
            onBlur={handleBlur}
            maxLength="50"
          />
          <div className="invalid-feedback fw-500 ps-1 mt-1">
            Enter your first name. <em>(50 characters max)</em>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label mb-0 ps-1" htmlFor="last-name">
            Last Name
          </label>
          <input
            className={`form-control fw-600 ${userInfo.lastName.isTouched && !userInfo.lastName.isValid && 'is-invalid'}`}
            id="last-name"
            type="text"
            name="lastName"
            onChange={handleChange}
            value={userInfo.lastName.value}
            onBlur={handleBlur}
            maxLength="50"
          />
          <div className="invalid-feedback fw-500 ps-1 mt-1">
            Enter your last name. <em>(50 characters max)</em>
          </div>
        </div>
        <div className="mb-1">
          <label className="form-label mb-0 ps-1" htmlFor="email">
            Email Address
          </label>
          <input
            className={`form-control fw-600 ${userInfo.email.isTouched && !userInfo.email.isValid && 'is-invalid'}`}
            id="email"
            type="email"
            name="email"
            onChange={handleChange}
            value={userInfo.email.value}
            onBlur={handleBlur}
          />
          <div className="invalid-feedback fw-500 ps-1 mt-1">Enter a valid email address.</div>
        </div>
      </form>
    </div>
  );
}
