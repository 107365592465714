import { useState, useEffect, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import cookies from '../../utils/cookies';
import Error from '../../components/shared/ErrorBoundary';
import OrderSummary from '../../components/shared/OrderSummary';
import CheckoutBtn from '../../components/shared/CheckoutBtn';
import CartSkeleton from '../../components/shared/CartSkeleton';
import CartEmpty from '../../components/shared/CartEmpty';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';
import useBrand from '../../hooks/useBrand';
import ToastNotification from '../../components/shared/ToastNotification';
import FeeDescriptionModal from '../../components/shared/FeeDescriptionModal';
import UpsellDescriptionModal from '../../components/fis/UpsellDescriptionModal';
import UpsellGroups from '../../components/fis/UpsellGroups';
import { ecomViewCart } from '../../utils/ecomHelper';

const Cart = () => {
  const { domain } = useBrand();
  const [searchParams, setSearchParams] = useSearchParams();
  const { cartId, productId, tp, coupon, groupId, host, bulk, testVariant } = Object.fromEntries([...searchParams]);
  const myCartId = cartId ? cartId : !cartId && !productId ? cookies.get('cartId') : null;
  const bulkCartId = cookies.get('bulkCartId');

  const [cartState, cartDispatch] = useContext(CartContext);

  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  // adding current-page-{pageName} class for styling the header breadcrumb section
  useEffect(() => {
    document.body.classList.add('current-page-cart');
  }, []);

  // setting testVariant for testing
  useEffect(() => {
    if (testVariant) {
      cookies.set('testVariant', testVariant);
    }
  }, [testVariant]);

  // we dont need the upsell modal for FIS, users will see the upsells by default
  useEffect(() => {
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        viewedUpsells: true,
      },
    });
  }, [cartDispatch]);

  const storeCartData = useCallback(
    (cartData) => {
      let cart = cartData;

      // console.log('cart', cart);

      // this sets and updates the local cart store making it available for all the components
      cartDispatch({
        type: 'UPDATE',
        payload: {
          ...cart,
          isLoading: false,
          isPageShowing: false,
        },
      });

      cookies.set('cartId', cart.cartId, 7);

      if (bulkCartId !== cart.cartId) {
        bulkCartId && cookies.delete('bulkCartId');
      }
      if (bulk === '1') {
        cookies.set('bulkCartId', cart.cartId, 7);
      }

      setIsCartLoaded(true);

      // push cart data to the dataLayer
      ecomViewCart(cart);

      // this removes the query strings form the URL
      if ([...searchParams].length > 0) {
        setSearchParams({}, { replace: true });
      }
    },
    [bulk, bulkCartId, cartDispatch, searchParams, setSearchParams]
  );

  const getCart = useCallback(
    (myCartId) => {
      API.getCart(myCartId)
        .then((res) => {
          const cartData = res.data;
          sessionStorage.removeItem('viewCheckout');
          storeCartData(cartData);
        })
        .catch((err) => {
          if (err?.name === 'CanceledError') {
            console.error('Request Aborted');
          } else if (err?.name === 'AxiosError') {
            // console.error(err);
            console.error(err?.response?.data);
          } else {
            console.error(err);
          }

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    },
    [cartDispatch, storeCartData]
  );

  const handleBackBtn = useCallback(
    (myCartId) => {
      // console.log('Pageshow event listener');

      if (myCartId && sessionStorage.getItem('viewCheckout') === 'true') {
        // console.log('IF Pageshow Cart useEffect');

        sessionStorage.removeItem('viewCheckout');
        // window.location.reload();

        cartDispatch({
          type: 'UPDATE',
          payload: {
            isLoading: true,
            isPageShowing: true,
          },
        });

        getCart(myCartId);
      }
    },
    [cartDispatch, getCart]
  );

  // get the cart using the query or cookie cartId
  useEffect(() => {
    // console.log('Normal Cart useEffect');

    // removing eventListener before adding one to prevent duplicates
    window.removeEventListener('pageshow', () => handleBackBtn(myCartId));
    window.addEventListener('pageshow', () => handleBackBtn(myCartId));

    // if only cartId is available, fetch the cart
    if (
      myCartId &&
      !sessionStorage.getItem('viewCheckout') &&
      !(productId || tp || groupId || coupon || host) &&
      !isCartLoaded
    ) {
      // console.log('IF Normal Cart useEffect');

      getCart(myCartId);
    }
  }, [coupon, getCart, groupId, handleBackBtn, host, isCartLoaded, myCartId, productId, tp]);

  // create a new cart everytime there is a productId query string
  useEffect(() => {
    if (productId) {
      // clear existing cart before processing anything
      cookies.delete('cartId');

      const productIds = productId ? productId.toLowerCase().split(',') : [];
      const cartData = {
        cartId: null, // set to null because we are creating a new cart
        productIds: [productIds[0]], // only use the first productId for FIS
        pricingVariant: tp || null,
        groupId: groupId || null,
        coupon: coupon || null,
        sourceHost: host || domain,
      };
      API.createCart(cartData)
        .then(async (res) => {
          const cartData = res.data;
          storeCartData(cartData);
          ecomViewCart(cartData);
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    }
  }, [cartDispatch, coupon, domain, groupId, host, productId, storeCartData, tp]);

  // update an existing cart based on URL query string
  useEffect(() => {
    // if there is an exising cartId and there's no productId but have price modifiers, update the cart
    if (myCartId && (tp || groupId || coupon || host)) {
      const cartData = {
        cartId: myCartId,
        productIds: [],
        pricingVariant: tp || null,
        groupId: groupId || null,
        coupon: coupon || null,
        sourceHost: host || domain,
      };
      API.updateCart(cartData)
        .then(async (res) => {
          const cartData = res.data;
          storeCartData(cartData);
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    }
  }, [cartDispatch, coupon, domain, groupId, host, myCartId, storeCartData, tp]);

  // the FIS cart will only allow one product at a time
  // the slice(0, 1) ensures that we only display the first product in the case the cart is modified elsewhere
  const cartItems = cartState.cartProducts?.slice(0, 1).map((item) => {
    const hasAccessoryUpsells = item.upSellProducts.length
      ? item.upSellProducts.filter((upsell) => upsell.upsellSectionId !== 5).length > 0
      : false;

    if (hasAccessoryUpsells) {
      return (
        <div className="single-cart-item" key={item.cart_ProductId}>
          <UpsellGroups item={item} />
        </div>
      );
    }
  });

  // if cart is not empty render the cart details
  if (cartItems && cartItems.length > 0) {
    return (
      <>
        <Helmet>
          <title>Cart Details</title>
        </Helmet>

        <div className={`container-fluid but-fixed v2 ${!cartItems ? 'cart-skeleton' : ''}`}>
          <div className="row my-4">
            <div className="col-12 col-lg-8 pe-xl-4 order-1 order-lg-0 mt-lg-3">
              <h1
                className={`fs-4 fw-500 mt-2 mt-lg-0 ${
                  cartState.cartProducts[0].upsellType === 'Replace' ? 'mb-3' : 'mb-2'
                }`}
              >
                {cartState.cartProducts[0].name}
              </h1>

              {cartState.cartProducts[0].upsellType !== 'Replace' && (
                <p className="mb-4">{cartState.cartProducts[0].description}</p>
              )}

              {cartItems}

              <div className="row justify-content-end my-5">
                <div className="col-md-6">
                  <CheckoutBtn />
                </div>
              </div>

              <div className="row justify-content-end my-5">
                <div className="col-md-12">
                  <div className="bg-info-subtle rounded p-2 px-3" role="alert">
                    After you complete your checkout, you&#39;ll be asked to create an account where we collect the necessary
                    information to process your application. It&#39;s really simple, and we&#39;ll get everything ready for
                    you!
                  </div>
                </div>
              </div>
            </div>
            <aside className="col-sm-12 col-md-12 col-lg-4 order-0 order-lg-1 mt-lg-3">
              <OrderSummary showQtyControls={false} />
            </aside>
            {/* <div className="col-12 col-lg-8 pe-lg-4 order-2 order-lg-2">
              <div className="row justify-content-end my-4">
                <div className="col-md-6">
                  <CheckoutBtn />
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <FeeDescriptionModal />

        <ToastNotification />

        <UpsellDescriptionModal />
      </>
    );
  }

  // if the cart is empty show empty cart message
  if (isCartEmpty || (!myCartId && !productId) || (cartItems && cartItems.length === 0)) {
    return <CartEmpty />;
  }

  // preloader skeleton screen
  return <CartSkeleton />;
};

export default Cart;

export const ErrorBoundary = () => {
  return <Error fromPage="Cart Page" />;
};
